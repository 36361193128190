import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import Container from './container';

const Heading = () => (
  <Container>
    <div className="mt-8">
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <a href="#" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            Back
          </a>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div className="flex">
                <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                  Scholar Fund
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Scholarship Junkies
                </a>
              </div>
            </li>
            <li>
              <div className="flex items-center">
                <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                  Scholarship Bank
                </a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="pb-8 border-b border-gray-200 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Search Scholarships
          </h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex items-center md:mt-0 md:ml-4 space-x-8">
          <a
            href="#"
            className="text-sm font-medium text-blue-400 hover:text-blue-500"
          >
            College Finance FAQ
          </a>
          <a
            href="#"
            className="text-sm font-medium text-blue-400 hover:text-blue-500"
          >
            Contact
          </a>
          <a
            href="#"
            className="text-sm font-medium text-blue-400 hover:text-blue-500"
          >
            Español
          </a>
          <StaticImage
            alt="Scholarship Junkies logo"
            placeholder="none"
            src="../images/logo.png"
            className="hidden sm:block"
            width={200}
          />
          {/*
          <button
            disabled
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Log in
          </button>
          */}
        </div>
      </div>
    </div>
  </Container>
);

export default Heading;
