import React, { useState } from 'react';

import Container from '../components/container';
import Filters, { FILTER_ID_REQUIREMENTS } from '../components/filters';
import Layout from '../components/layout';
import ScholarshipList from '../components/scholarship-list';

const IndexPage = () => {
  const [denyRequirements, setDenyRequirements] = useState([] as string[]);

  return (
    <Layout>
      <title>
        Scholarship Bank
      </title>
      <Container>
        <main className="text-gray-800">
          <Filters
            onChange={(filters) => {
              setDenyRequirements(
                filters.find(({ id }) => id === FILTER_ID_REQUIREMENTS)!
                  .options
                  .filter((opt) => !opt.selected)
                  .map((opt) => opt.value),
              );
            }}
          />
          <div className="mx-auto max-w-5xl my-16">
            <ScholarshipList denyRequirements={denyRequirements} />
          </div>
        </main>
      </Container>
    </Layout>
  );
};

export default IndexPage;
