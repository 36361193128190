import {
  CalendarIcon,
  CurrencyDollarIcon,
  UsersIcon,
} from '@heroicons/react/solid';
import React, {
  FC,
  useEffect,
  useState,
} from 'react';

interface Scholarship {
  id: string;
  name: string;
  amount: number;
  deadline: string;
  description: string;
  requirements: {
    id: string;
    name: string;
  }[];
  url: string;
}

interface Props {
  denyRequirements: string[];
}

const ScholarshipList: FC<Props> = ({ denyRequirements }) => {
  const [scholarships, setScholarships] = useState([] as Scholarship[]);

  useEffect(
    () => {
      let stillValid = true;
      interface ScholarshipsResponse {
        records: {
          id: string;
          fields: Partial<{
            Link: string;
            Name: string;
            Description: string;
            '$$$ - Individual': number;
            Deadline: string;
            requirementIds: string[];
            requirementNames: string[];
          }>;
        }[];
      }
      const url = new URL('https://bank-search-handler.scholarfund.workers.dev/scholarships');
      url.searchParams.set('denyRequirements', denyRequirements.join(';'));
      fetch(url.toString())
        .then((resp) => resp.json() as Promise<ScholarshipsResponse>)
        .then((data) => {
          if (stillValid) {
            setScholarships(data.records.map((rec) => ({
              id: rec.id,
              name: rec.fields.Name ?? '',
              amount: rec.fields['$$$ - Individual'] ?? 0,
              deadline: rec.fields.Deadline ?? '',
              description: rec.fields.Description ?? '',
              requirements: rec.fields.requirementIds?.map((reqId, i) => ({
                id: reqId,
                name: rec.fields.requirementNames?.[i] ?? '',
              })) ?? [],
              url: rec.fields.Link ?? '#',
            })));
          }
        })
        .catch(console.error);
      return () => {
        stillValid = false;
      };
    },
    [denyRequirements],
  );

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {scholarships.map((scholarship) => (
          <li key={scholarship.id}>
            <a
              href={scholarship.url}
              className="block"
              target="_blank"
              rel="noreferrer"
            >
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p
                    className="text-sm font-medium text-blue-600 truncate"
                    title={scholarship.name}
                  >
                    {scholarship.name}
                  </p>
                  <div className="mt-2 ml-2 flex-shrink-0 flex sm:mt-0">
                    <p className="flex items-center text-sm text-gray-500">
                      <UsersIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Undergrad, Grad School
                    </p>
                    <p className="flex items-center text-sm text-green-500 sm:ml-6">
                      <CurrencyDollarIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                      $
                      {scholarship.amount.toLocaleString()}
                    </p>
                    <p className="flex items-center text-sm text-gray-500 sm:ml-6">
                      <CalendarIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <div>
                        {'Deadline: '}
                        <time dateTime={scholarship.deadline}>
                          {scholarship.deadline}
                        </time>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="sm:flex justify-start space-x-2">
                  {scholarship.requirements.map((requirement) => (
                    <p
                      className={`
                          mt-2 max-w-xs truncate
                          px-2 text-xs leading-5 font-semibold
                          rounded-full bg-teal-100 text-teal-800
                        `}
                      key={requirement.id}
                      title={requirement.name}
                    >
                      {requirement.name}
                    </p>
                  ))}
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="w-full mt-2 text-sm text-gray-500">
                    <p
                      className="truncate"
                      title={scholarship.description}
                    >
                      {scholarship.description}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScholarshipList;
