import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import React, {
  FC,
  Fragment,
  useEffect,
  useState,
} from 'react';

const sortOptions = [
  { name: 'Deadline', href: '#' },
  { name: 'Award $', href: '#' },
  { name: 'Newest', href: '#' },
];

interface Filter {
  id: string;
  name: string;
  options: {
    value: string;
    label: string;
    selected: boolean;
  }[];
}

type SelectedFilterOptions = Partial<Record<
  string,
  {
    selected: string[];
    unselected: string[];
  }
>>;

const classNames = (...classes: string[]): string => classes.filter(Boolean).join(' ');

export const FILTER_ID_REQUIREMENTS = 'requirements';

interface Props {
  onChange?(filters: Filter[]): void;
}

const Filters: FC<Props> = ({ onChange }) => {
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState([
    {
      id: 'edu_level',
      name: 'Education Level',
      options: [
        {
          value: 'undergrad',
          label: 'Undergrad',
          selected: true,
        },
        {
          value: 'grad_school',
          label: 'Grad School',
          selected: true,
        },
      ],
    },
    {
      id: 'field_of_study',
      name: 'Field of Study',
      options: [],
    },
    {
      id: FILTER_ID_REQUIREMENTS,
      name: 'Requirements',
      options: [],
    },
  ] as Filter[]);

  useEffect(
    () => {
      const cachedSelections = JSON.parse(
        (window.localStorage.getItem('selectedFilterOptions') ?? '') || '{}',
      ) as SelectedFilterOptions;
      interface RequirementsData {
        records: {
          id: string;
          createdTime: string;
          fields: Partial<{
            ID: number;
            Name: string;
            'Include by Default': true;
          }>;
        }[];
      }
      fetch('https://bank-search-handler.scholarfund.workers.dev/requirements')
        .then((res) => res.json())
        .then(({ records }: RequirementsData) => {
          setFilters((prev) => prev.map((filter) => (
            filter.id === FILTER_ID_REQUIREMENTS
              ? {
                ...filter,
                options: records
                  .map((rec) => ({
                    value: `${rec.fields.ID ?? ''}`,
                    label: rec.fields.Name ?? '',
                    selected: cachedSelections[FILTER_ID_REQUIREMENTS]?.selected.includes(
                      `${rec.fields.ID ?? ''}`,
                    )
                      || (
                        !cachedSelections[FILTER_ID_REQUIREMENTS]?.unselected.includes(
                          `${rec.fields.ID ?? ''}`,
                        )
                        && Boolean(rec.fields['Include by Default'])),
                  }))
                  .sort((a, b) => (a.label < b.label ? -1 : 1)),
              }
              : filter
          )));
        })
        .catch(console.error);
    },
    [],
  );

  useEffect(() => onChange && onChange(filters), [filters]);

  const toggleOption = (sectionId: string, optionValue: string): void => {
    const transition = (prevFilters: Filter[]): Filter[] => prevFilters.map(
      (prevFilter) => (prevFilter.id === sectionId
        ? {
          ...prevFilter,
          options: prevFilter.options.map(
            (prevOption) => (prevOption.value === optionValue
              ? {
                ...prevOption,
                selected: !prevOption.selected,
              }
              : prevOption),
          ),
        }
        : prevFilter),
    );
    const newFilters = transition(filters);
    setFilters(newFilters);
    window.localStorage.setItem(
      'selectedFilterOptions',
      JSON.stringify(Object.fromEntries(newFilters.map((section) => [
        section.id,
        {
          selected: section.options.filter((opt) => opt.selected).map((opt) => opt.value),
          unselected: section.options.filter((opt) => !opt.selected).map((opt) => opt.value),
        },
      ])) as SelectedFilterOptions),
    );
  };

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto" // eslint-disable-line max-len
              >
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500" // eslint-disable-line max-len
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.name}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open: disclosureOpen }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button
                              className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400" // eslint-disable-line max-len
                            >
                              <span className="font-medium text-gray-900">{section.name}</span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    disclosureOpen
                                      ? '-rotate-180'
                                      : 'rotate-0',
                                    'h-5 w-5 transform',
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div key={option.value} className="flex items-center">
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    className="h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500" // eslint-disable-line max-len
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="text-center">
        <section aria-labelledby="filter-heading" className="py-6">
          <h2 id="filter-heading" className="sr-only">
            Product filters
          </h2>

          <div className="flex items-center justify-between">
            <Menu as="div" className="relative z-10 inline-block text-left">
              <div>
                <Menu.Button
                  className={`
                    group inline-flex justify-center text-sm font-medium text-gray-700
                    hover:text-gray-900
                  `}
                >
                  Sort: Deadline
                  <ChevronDownIcon
                    className={`
                      flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400
                      group-hover:text-gray-500
                    `}
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`
                    origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md
                    shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
                  `}
                >
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm font-medium text-gray-900',
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden" // eslint-disable-line max-len
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filters.map((section) => (
                <Popover
                  as="div"
                  key={section.name}
                  id="desktop-menu"
                  className="relative z-10 inline-block text-left"
                >
                  <div>
                    <Popover.Button
                      className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900" // eslint-disable-line max-len
                    >
                      <span>
                        {section.name}
                      </span>
                      <span
                        className="ml-1.5 rounded-full py-0.5 px-1.5 bg-blue-100 text-xs font-semibold text-gray-700 tabular-nums" // eslint-disable-line max-len
                      >
                        {section.options.filter((opt) => opt.selected).length}
                      </span>
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500" // eslint-disable-line max-len
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel
                      className={`
                        origin-top-right absolute right-0 mt-2 bg-white rounded-md
                        shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none
                        max-h-96 overflow-y-auto
                      `}
                    >
                      <form className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              checked={option.selected}
                              defaultValue={option.value}
                              onClick={() => toggleOption(section.id, option.value)}
                              type="checkbox"
                              className={`
                                h-4 w-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500
                              `}
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className={`
                                ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap
                              `}
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ))}
            </Popover.Group>
          </div>
        </section>
      </div>
    </div>
  );
};

Filters.defaultProps = {
  onChange: undefined,
};

export default Filters;
