import React, { FC } from 'react';

import Footer from './footer';
import Heading from './heading';

const Layout: FC = (props) => (
  <>
    <Heading />
    {props.children}
    <Footer />
  </>
);

export default Layout;
